import { fbTrack, gaTrafficToSignup } from "./analytics";
import { APP_SIGN_UP_URL } from "./constants";
import Cookies from "js-cookie";

function redirect(to, label = "") {
  if (typeof window !== "undefined") {
    const isTesting = Cookies.get("testingEvents") === "true";

    if (isTesting) {
      console.log("The session is auto testing");
    } else if (to === APP_SIGN_UP_URL) {
      fbTrack("track", "Lead");
      gaTrafficToSignup(label);
    }

    window.open(to, "_blank");
  }
}

export default redirect;

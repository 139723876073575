import ReactGA from "react-ga";

const GOOGLE_ANALYTICS_ID = "UA-99671636-3";

function gaInit() {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID);
}

function gaLanded(experimentName, variantName) {
  // How many users landed on our website with variant X
  console.log(
    `Traffic from experiment "${experimentName}" variant ${variantName}`
  );

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (typeof window !== "undefined" && window.ga) {
    ReactGA.event({
      category: "traffic",
      action: `Traffic from experiment "${experimentName}" variant "${variantName}"`,
    });
  } else {
    setTimeout(gaLanded, 100);
  }
  // TODO: This is PRO feature of Hotjar
  // if (window.hj) {
  //   window.hj("trigger", "split_landing_" + variantName);
  // }
}

function gaConvert(experimentName, variantName) {
  // How many users completed the subsribe process with variant X
  console.log(
    `Convert from experiment "${experimentName}" variant "${variantName}"`
  );

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (typeof window !== "undefined" && window.ga) {
    ReactGA.event({
      category: "convert",
      action: `Convert from experiment "${experimentName}" variant ${variantName}`,
    });
  } else {
    setTimeout(gaConvert, 100);
  }
}

function gaTrafficToSignup(label) {
  console.log(`Traffic for signup with label=${label}`);

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Conversion",
      action: `Total Traffic`,
      label,
    });
  } else {
    setTimeout(gaTrafficToSignup, 100);
  }
}

function gaExampleClicked(exampleName) {
  // How many users completed the subsribe process with variant X
  console.log(`Example "${exampleName}" clicked`);

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (typeof window !== "undefined" && window.ga) {
    ReactGA.event({
      category: "education",
      action: exampleName,
    });
  } else {
    setTimeout(gaConvert, 100);
  }
}

function gaVisitor(jobTitle) {
  // How many users completed the subsribe process with variant X
  console.log(`Convert from experiment visitor as "${jobTitle}"`);

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (typeof window !== "undefined" && window.ga) {
    ReactGA.event({
      category: "convert",
      action: jobTitle,
    });
  } else {
    setTimeout(gaVisitor, 100);
  }
}

function gaPurchase() {
  // How many users completed the subsribe process with variant X
  console.log(`Convert from category "Conversion" with action "Purchase"`);

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (typeof window !== "undefined" && window.ga) {
    ReactGA.event({
      category: "conversion",
      action: "Purchase",
    });
  } else {
    setTimeout(gaPurchase, 100);
  }
}

function fbTrack(a, b) {
  console.log("fbTrack: ", a, b);

  if (
    process.env.NODE_ENV === `production` &&
    typeof window.fbq === `function`
  ) {
    window.fbq(a, b);
  }
}

export {
  gaInit,
  gaLanded,
  gaConvert,
  fbTrack,
  gaVisitor,
  gaPurchase,
  gaExampleClicked,
  gaTrafficToSignup,
};

import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Twitter from "./Twitter";
import favicon from "../../../assets/favicon.ico";
// https://github.com/LekoArts/gatsby-starter-prismic/blob/master/gatsby-config.js

const SEO = ({
  title,
  description,
  pathname,
  children,
  customOgImage,
  customTitle,
  customFavicon = null,
  tool = null,
  customTwitterOg = "",
}) => {
  const { site } = useStaticQuery(query);

  const {
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      ogImage,
      headline,
      siteLanguage,
      twitter,
    },
  } = site;

  const canonicalURL = `${siteUrl}${pathname}`;
  const subRoute = title ? title + " | " : "";
  const seoTitle = subRoute + defaultTitle + " - " + headline;

  console.log(`${title} vs ${seoTitle}`);

  const seo = {
    title: customTitle || seoTitle,
    description: description || defaultDescription,
    image: customOgImage || ogImage,
    url: canonicalURL,
    type: `website`,
  };

  const twitterOgImage = customTwitterOg || seo.image;
  const icon = customFavicon || favicon;
  // =======================
  // Define Structured Data
  // =======================
  // schema.org in JSONLD format https://developers.google.com/search/docs/guides/intro-structured-data

  // 1. Setup 'Organization' type
  const schemaOrgWebPage = {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: siteUrl,
    logo:
      "https://loceye-master-app.s3-eu-west-1.amazonaws.com/visualeyes/logo-rect.png",
    mainEntityOfPage: siteUrl,
    description: seo.description,
    name: "VisualEyes - " + headline,
  };

  // 2. Setup 'WebSite' type
  const websiteStructureData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    url: siteUrl,
    headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: siteUrl,
    description: seo.description,
    name: "VisualEyes - " + headline,
  };

  // 3. Setup 'BreadcrumbList' type

  // Build the 'BreadcrumbList' type with 'ListItem' levels
  const rootItemListElement = [
    {
      "@type": "ListItem",
      item: "https://www.visualeyes.design",
      position: 1,
      name: "Homepage",
    },
  ];
  const itemListElement = [rootItemListElement];

  const hasThirrdLevel = tool;
  const hasSecondLevel = title != null;

  if (hasThirrdLevel) {
    // Third depth level (only tools for now)
    itemListElement.push({
      "@type": "ListItem",
      item: "https://www.visualeyes.design/tools",
      position: 2,
      name: "Tools & Resources",
    });
    itemListElement.push({
      "@type": "ListItem",
      item: "https://www.visualeyes.design/tools" + tool.url,
      position: 3,
      name: tool.title,
    });
  } else if (hasSecondLevel) {
    // Second depth layer e.g. /how, /features
    itemListElement.push({
      "@type": "ListItem",
      item: seo.url,
      position: 2,
      name: title,
    });
  }

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement,
  };

  return (
    <Helmet title={seo.title}>
      <html lang={siteLanguage} />
      <meta name="title" content={seo.title} />
      <meta name="description" content={seo.description} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content={seo.type} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:site_name" content="VisualEyes" />
      <meta property="og:locale" content="en_US" />
      <link rel="icon" href={icon} />
      <link rel="canonical" href={canonicalURL} />
      <meta
        name="keywords"
        content="design, design analytics, ux, ui, ai, heatmap, attention, attention heatmap, clarity"
      />

      {/* Twitter */}
      <meta name="twitter:card" content={"summary_large_image"} />
      <meta name="twitter:creator" content={twitter} />
      <meta name="twitter:site" content={twitter} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={twitterOgImage} />
      <meta name="twitter:image:alt" content={seo.description} />

      {/* Insert schema.org data Organization/WebSite/Breadcrumps */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgWebPage)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(websiteStructureData)}
      </script>
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>

      {children}
    </Helmet>
  );
};

export default SEO;
SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  node: PropTypes.object,
};

SEO.defaultProps = {
  title: null,
  desc: null,
  banner: null,
  pathname: null,
  article: false,
  node: null,
};

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        ogImage
        twitterImage
        headline
        siteLanguage
        ogLanguage
        author
        organisation
        twitter
        keywords
      }
    }
  }
`;
